//Get buildings from array

import { ZERO } from "../constants/common";

export const getBuildngs = (buildngs: [{ bName: string }]) => {
  let selectedBldgngs = null;
  for (let i = ZERO; i < buildngs?.length; i++) {
    if (i > ZERO) {
      selectedBldgngs = selectedBldgngs + ", " + buildngs[i].bName;
    } else {
      selectedBldgngs = buildngs[i].bName;
    }
  }
  return selectedBldgngs;
};
