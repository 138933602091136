/**
 * @file   src\containers\RallyPoints.tsx
 * @brief  RallyPoints page.
 * @date   March, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from "../assets/strings/Strings.json";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";

import {
  HTTP_RESPONSE_STATUS_200,
  USER_INFO_KEY,
  PHONE,
  ONE,
  PAGE_SIZE_20,
  MAX_LENGTH_100,
  MAX_LENGTH_2000,
  MAX_LENGTH_12,
  ZERO,
  ACCEPTED_IMAGE_FORMATS,
  SUPPORTED_FORMATS_ARRAY,
  tableHeaders,
  MAX_FILE_SIZE,
  MAX_LENGTH_3,
  EXTENSION,
} from "../constants/common";
import { formatPhoneNumber, getLocalStorage } from "../helpers/common";

import TableHeader from "../components/common/TableHeader";
import Paginate from "../components/common/Paginate";
import Search from "../components/common/Search";
import ICEdit from "../assets/img/icons/Edit";
import SLInput from "../components/SLInput";
import SLFileUpload from "../components/SLFileUpload";
import {
  createRallyPoint,
  deleteRallyPoint,
  updateRallyPoint,
} from "../services/rallyPointService";
import {
  phoneFormat,
  phoneNumberValidation,
  numberSpecialCharValidation,
  numberValidation,
  numberOnly,
} from "../helpers/validations";
import { toast } from "react-toastify";
import { getPresignedUrl, uploadToS3 } from "../services/fileUploadService";
import ICRemove from "../assets/img/icons/Remove";
import DeleteConfModal from "../components/common/DeleteConfModal";
import {
  RallyPointsForm,
  FormError,
  CreateUpdateReqPayload,
  DeleteRallyPoint,
} from "../interfaces/RallyPoints";
import {
  INVALID_IMAGE_TYPE,
  INVALID_IMAGE_SIZE,
  INVALID_IMAGE_DIMENSION,
  ENTER_BUILDING_NAME,
  ENTER_DESCRIPTION,
  ENTER_PHONE,
  ENTER_POINT_CONTRACT,
  ENTER_RALLY_POINT,
  SELECT_IMAGE,
  ENTER_VALID_PHONE,
} from "../constants/validationMessages";
import { fetchRallyPointList } from "../Redux/actions/rallyPoints";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import { SearchRequest } from "../interfaces/Search";
import { PreSignedUrl } from "../interfaces/FileUpload";

const RallyPoints = () => {
  //Initial Form values
  const initialValues = {
    buildngName: "",
    rallyPoint: "",
    desc: "",
    pointOfContact: "",
    phone: "",
    image: "",
    extension: "",
  };

  //Initial Form errors
  const initialErrors = {
    buildngNameError: "",
    rPError: "",
    descError: "",
    contactError: "",
    phoneError: "",
    imageError: "",
  };

  const [rallyPoints, setRallyPoints] = useState<any>([]); //State for rally point list

  //Pagination initial states
  const [page, setPage] = useState<number>(ONE);
  const [perPage, setPerPage] = useState<number>(PAGE_SIZE_20);
  const [totalRecord, setTotalRecord] = useState<number>(ZERO);
  const [searchText, setSearchText] = useState<string>("");
  const [load, setLoad] = useState<boolean>(false); // Loader state
  const [formData, setFormData] = useState<RallyPointsForm>(initialValues);
  const [formError, setFormError] = useState<FormError>(initialErrors);
  const [selectedFile, setSelectedFile] = useState<File>(null);
  const [rallyId, setRallyId] = useState<number>(null);
  const [deletePayload, setDeletPayload] = useState<DeleteRallyPoint>(null);
  const [showAddEditPopup, setShowPopup] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>(null);
  const [showDeletePopup, setDeletePopUp] = useState<boolean>(null);
  const [isDisable, setBtnDisable] = useState<boolean>(false);
  const handleDeletePopUp = (state: boolean) => setDeletePopUp(state);

  const MIN_WIDHT = 500;
  const MIN_HEIGHT = 500;

  //Selector for get rally point list from Redux store.
  const rallyPointsLists = useAppSelector(
    (state: any) => state?.rallyPoints?.list
  );

  //Selector for check the rally point list loading status for running the loader in the UI before the records fetched completely.
  const rallyPointsListLoading = useAppSelector(
    (state: any) => state?.rallyPoints?.loading
  );
  const dispatch = useAppDispatch();

  // Table columns
  const columns = [
    { title: tableHeaders.rallyPoint.buildingName, isSort: false },
    { title: tableHeaders.rallyPoint.rallyPoint, isSort: false },
    { title: tableHeaders.rallyPoint.desc, isSort: false },
    { title: tableHeaders.rallyPoint.conatctPerson, isSort: false },
    { title: tableHeaders.rallyPoint.phone, isSort: false },
    {
      title: tableHeaders.rallyPoint.image,
      isSort: false,
      thClass: "text-center",
    },
    { title: tableHeaders.common.edit, isSort: false, thClass: "text-center" },
    {
      title: tableHeaders.common.remove,
      isSort: false,
      thClass: "text-center",
    },
  ];

  // Close the add/Edit Rally point form Popup
  const closePopUp = () => {
    setShowPopup(false);
    setRallyId(null);
    setSelectedFile(null);
    setFormData(initialValues);
    setFileName(null);
    setFormError(initialErrors);
  };

  // Open the add/Edit Rally point form Popup
  const openPopUp = () => {
    setShowPopup(true);
    setBtnDisable(false);
  };

  //File upload
  const uploadRallyPointImage = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    setFormError((error) => ({
      ...error,
      imageError: null,
    }));
    setSelectedFile(null);
    try {
      const image = new Image();
      const file = event.target?.files[ZERO];
      setFileName(file.name);
      let isValidImg = true;
      if (!SUPPORTED_FORMATS_ARRAY.includes(file.type)) {
        setFormError((error) => ({
          ...error,
          imageError: INVALID_IMAGE_TYPE,
        }));
        isValidImg = false;
      }
      if (file?.size > MAX_FILE_SIZE) {
        setFormError((error) => ({
          ...error,
          imageError: INVALID_IMAGE_SIZE,
        }));
        isValidImg = false;
      }
      if (isValidImg) {
        image.src = URL.createObjectURL(file); // This is for getting image width and height
        image.addEventListener("load", async () => {
          if (image.height < MIN_HEIGHT || image.width < MIN_WIDHT) {
            setFormError((error) => ({
              ...error,
              imageError: INVALID_IMAGE_DIMENSION.replace(
                "[RESOLUTION]",
                `${MIN_WIDHT}X${MIN_HEIGHT}`
              ),
            }));
          } else {
            setSelectedFile(file);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // This will trigger in each pagination changes.
  useEffect(() => {
    dispatch(
      fetchRallyPointList({
        pageNo: page,
        pageSize: perPage,
        searchText: searchText,
      })
    );
  }, [page, perPage]);

  //This is for keep the rally point list in the local state varibale after loading the records from the redux store.
  useEffect(() => {
    if (!rallyPointsListLoading) {
      loadRallyPointList();
    }
  }, [rallyPointsLists]);

  //Function for set list in the local state and managing the loader functionalities.
  const loadRallyPointList = async () => {
    const list = rallyPointsLists?.list;
    if (list) {
      setTotalRecord(rallyPointsLists?.totalRecords);
      setRallyPoints(list);
    } else setTotalRecord(ZERO);
  };

  //Search functionality
  const onSearchClick = (payload: SearchRequest) => {
    dispatch(fetchRallyPointList(payload));
  };

  // page change and state change
  const pageChange = (page: any) => {
    if (page) {
      setPage(page);
    }
  };

  // page size change and state change
  const perPageChange = (page: any) => {
    if (page) {
      setPage(ONE);
      setPerPage(page);
    }
  };

  //Edit button click and the data populated on the each text fields on the modal popup form.
  const onEdit = (rallyPoint: any) => {
    openPopUp();

    setRallyId(rallyPoint?.id);
    const imagePath: string = rallyPoint?.img;
    setFormData((formData) => ({
      ...formData,
      buildngName: rallyPoint.buildingName,
      rallyPoint: rallyPoint.rallyPoint,
      desc: rallyPoint.rp_desc,
      pointOfContact: rallyPoint.point_of_Contact,
      phone: rallyPoint.phone,
      image: imagePath,
      extension: rallyPoint.extension,
    }));
    const fileName: string[] = imagePath?.split("/");
    if (fileName) {
      setFileName(fileName[fileName?.length - ONE]);
    }
  };

  // Html table building for rally point list
  function LoadRallyPointsList() {
    return (
      rallyPoints?.length > ZERO &&
      rallyPoints.map((item: any, key: any) => (
        <tr key={key}>
          <td>{item?.buildingName}</td>
          <td>{item?.rallyPoint}</td>
          <td>{item?.rp_desc}</td>
          <td>{item?.point_of_Contact}</td>
          <td className="text-nowrap">
            {item?.phone && formatPhoneNumber(item?.phone)}

            {item?.extension && (
              <span>
                {" "}
                <b>&nbsp;Ext:</b> {item?.extension}
              </span>
            )}
          </td>
          <td>
            <div className="m-auto rally-image">
              <img src={item?.img} />
            </div>
          </td>
          <td className="ic-blue" onClick={() => onEdit(item)}>
            <ICEdit />
          </td>
          <td
            className="text-center ic-yellow"
            onClick={() => {
              handleDeleteRallyPoint(item?.id, item?.img);
            }}
          >
            <ICRemove />
          </td>
        </tr>
      ))
    );
  }

  // For each text changes in the form and updated in the corresponding state variables.
  const onTextBoxChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    if (currentId === PHONE) {
      if (numberSpecialCharValidation.test(value)) {
        const numbers = value.match(/\d+/g);
        const fullNumber = numbers.join("");
        setFormData((formData) => ({
          ...formData,
          [currentId]: fullNumber,
        }));
      } else if (!numberValidation.test(value)) {
        setFormData((formData) => ({
          ...formData,
          [currentId]: value,
        }));
      }
    } else if (currentId === EXTENSION) {
      if (value) {
        if (numberOnly.test(value)) {
          setFormData((formData) => ({
            ...formData,
            [currentId]: value,
          }));
        }
      } else {
        setFormData((formData) => ({
          ...formData,
          [currentId]: "",
        }));
      }
    } else {
      setFormData((form: any) => ({
        ...form,
        [currentId]: value,
      }));
    }
  };

  //Save functionality after create/update the details.
  const handleSave = async () => {
    const isValid = validateFormData();
    const userInfoFromLS = getLocalStorage(USER_INFO_KEY);
    const userId = userInfoFromLS?.userId;
    const orgId = userInfoFromLS?.orgId;
    const timeStamp = Date.now();
    const fileName = selectedFile?.name?.replace(/ /g, "-");
    const reqPayload: PreSignedUrl = {
      keys: [
        "RallyPoint/" + userId + "_" + orgId + "_" + timeStamp + "_" + fileName,
      ],
    };

    if (isValid) {
      const payload: CreateUpdateReqPayload = {
        buildingName: formData?.buildngName,
        rallyPoint: formData?.rallyPoint,
        rp_desc: formData?.desc,
        point_of_contact: formData?.pointOfContact,
        phone: formData?.phone,
        extension: formData?.extension,
      };
      if (selectedFile) {
        setLoad(true);
        try {
          const response = await getPresignedUrl(reqPayload);
          if (response?.status === HTTP_RESPONSE_STATUS_200) {
            const preSignedUrl = response?.data[ZERO];
            const res = await uploadToS3(preSignedUrl, selectedFile);
            const fileFullUrl = res?.config?.url;
            const fileS3Url = fileFullUrl ? fileFullUrl.split("?")[ZERO] : ""; // For excluding Aws credentials from the url and access s3 image Url.
            payload.img = fileS3Url; //formData?.image,
          }
        } catch {
          alert(Strings.fileUploadError);
        }
        setLoad(false);
      }
      let response: ResponseObjects;
      setBtnDisable(true);
      if (rallyId) {
        payload.rpId = rallyId;
        payload.oldImg = "";
        if (selectedFile) {
          payload.oldImg = formData?.image;
        }
        response = await updateRallyPoint(payload);
        formReset(response?.status, response?.message);
      } else {
        response = await createRallyPoint(payload);
        formReset(response?.status, response?.message);
      }
    }
  };

  //Reset and state changes into initial states after saving the form data based on the response status.
  const formReset = (resStatus: any, resMessage: string) => {
    if (resStatus === HTTP_RESPONSE_STATUS_200) {
      toast.success(resMessage);
      dispatch(
        fetchRallyPointList({
          pageNo: ONE,
          pageSize: PAGE_SIZE_20,
          searchText: searchText,
        })
      );
      setRallyId(null);
      setSelectedFile(null);
      setFormData(initialValues);
      closePopUp();
      setFileName(null);
    } else {
      setBtnDisable(false);
      toast.error(resMessage);
    }
  };

  // Validating the each form data.
  const validateFormData = () => {
    //setFormError(initialErrors);
    setFormError((error) => ({
      ...error,
      buildngNameError: null,
      rPError: null,
      descError: null,
      contactError: null,
      phoneError: null,
    }));
    let isValid = true;
    const building = formData?.buildngName?.trim();
    const rallyPoint = formData?.rallyPoint?.trim();
    const description = formData?.desc?.trim();
    const contact = formData?.pointOfContact?.trim();
    const phone = formData?.phone?.trim();

    if (!building) {
      setFormError((error) => ({
        ...error,
        buildngNameError: ENTER_BUILDING_NAME,
      }));
      isValid = false;
    }
    if (!rallyPoint) {
      setFormError((error) => ({
        ...error,
        rPError: ENTER_RALLY_POINT,
      }));
      isValid = false;
    }
    if (!description) {
      setFormError((error) => ({
        ...error,
        descError: ENTER_DESCRIPTION,
      }));
      isValid = false;
    }
    if (!contact) {
      setFormError((error) => ({
        ...error,
        contactError: ENTER_POINT_CONTRACT,
      }));
      isValid = false;
    }
    if (!phone) {
      setFormError((error) => ({
        ...error,
        phoneError: ENTER_PHONE,
      }));
      isValid = false;
    } else {
      const phoneValidation = phoneNumberValidation(
        phoneFormat(formData?.phone)
      );
      if (!phoneValidation) {
        setFormError((error) => ({
          ...error,
          phoneError: ENTER_VALID_PHONE,
        }));
        isValid = false;
      }
      // const validPhone = isPhoneNumber(phone);
      // if (!validPhone) {
      //   setFormError((error) => ({
      //     ...error,
      //     phoneError: "Enter valid phone number.",
      //   }));
      //   isValid = false;
      // }
    }
    if (formError?.imageError) {
      isValid = false;
    } else {
      if (!rallyId && !selectedFile) {
        setFormError((error) => ({
          ...error,
          imageError: SELECT_IMAGE,
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  // Delete rally point click will trigger here initially and show the confirmation popup and set the deleting item id into the state.
  const handleDeleteRallyPoint = (id: number, image?: string) => {
    setBtnDisable(false);
    handleDeletePopUp(true);
    setDeletPayload({ itemId: id, image: image });
  };

  // Delete confim will trigger here.
  const deleteConfirm = async () => {
    const payload: DeleteRallyPoint = deletePayload;
    try {
      setBtnDisable(true);
      const response: ResponseObjects = await deleteRallyPoint({
        rpId: payload?.itemId,
        oldImg: payload.image,
      });
      if (response?.status === HTTP_RESPONSE_STATUS_200) {
        toast.success(response?.message);
        const copyList: any = [...rallyPoints];
        const updatedList = copyList?.filter(function (ls: any) {
          return ls.id !== payload.itemId;
        });

        setRallyPoints(updatedList);
        handleDeletePopUp(false);
        setDeletPayload(null);
      } else {
        setBtnDisable(false);
        toast.error(response?.message);
      }
    } catch {
      alert("Something went wrong.");
    }
  };

  return (
    <>
      <Row className="tilte-wrapper">
        <Col lg="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.RallyPoints.Title}
          </h1>
        </Col>
      </Row>
      <Row className="action-wrapper">
        <Col md="6" xl="4">
          <Search
            onClick={onSearchClick}
            pageSize={perPage}
            setPage={setPage}
            page={page}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Col>
        <Col md="6" xl="8" className="text-end">
          <Button onClick={() => openPopUp()}>
            {Strings.RallyPoints.addBtn}
          </Button>
        </Col>
      </Row>
      <Table responsive>
        <TableHeader columns={columns} />
        <tbody className="vertical-middle">
          <LoadRallyPointsList />
        </tbody>
      </Table>
      {!rallyPointsListLoading &&
      rallyPoints &&
      rallyPoints?.length === ZERO ? (
        <Col className="text-center no-record">
          {Strings.RallyPoints.NoDataMessage}
        </Col>
      ) : (
        ""
      )}
      {rallyPointsListLoading && (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      )}
      {totalRecord !== ZERO && (
        <Paginate
          totalRecords={totalRecord}
          currentPage={page}
          perPage={perPage}
          onClick={pageChange}
          onShowClick={perPageChange}
        />
      )}

      {/* Remove User Modal */}
      <Modal
        show={showAddEditPopup}
        onHide={closePopUp}
        backdrop="static"
        centered
        size="xl"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {rallyId
              ? Strings.RallyPoints.Modal.editTitle
              : Strings.RallyPoints.Modal.addTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="alert-info">
          <Row>
            <Col lg="6">
              <SLInput
                id="buildngName"
                type="text"
                label={Strings.RallyPoints.Modal.buildngName}
                value={formData?.buildngName}
                onChange={onTextBoxChange}
                errorMessage={formError?.buildngNameError}
                maxLength={MAX_LENGTH_100}
              ></SLInput>
            </Col>
            <Col lg="6">
              <SLInput
                id="rallyPoint"
                type="text"
                label={Strings.RallyPoints.Modal.rallyPoint}
                value={formData?.rallyPoint}
                onChange={onTextBoxChange}
                errorMessage={formError?.rPError}
                maxLength={MAX_LENGTH_100}
              ></SLInput>
            </Col>

            <Col lg="6">
              <SLInput
                id="desc"
                type="text"
                label={Strings.RallyPoints.Modal.desc}
                value={formData?.desc}
                onChange={onTextBoxChange}
                errorMessage={formError?.descError}
                maxLength={MAX_LENGTH_2000}
              ></SLInput>
            </Col>
            <Col lg="6">
              <SLInput
                id="pointOfContact"
                type="text"
                label={Strings.RallyPoints.Modal.contact}
                value={formData?.pointOfContact}
                onChange={onTextBoxChange}
                errorMessage={formError?.contactError}
                maxLength={MAX_LENGTH_100}
              ></SLInput>
            </Col>
            <Col lg="4">
              <SLInput
                id={PHONE}
                type="tel"
                maxLength={MAX_LENGTH_12}
                label={Strings.RallyPoints.Modal.phone}
                value={phoneFormat(formData?.phone) || ""}
                onChange={onTextBoxChange}
                errorMessage={formError?.phoneError}
              ></SLInput>
            </Col>
            <Col md="2">
              <SLInput
                label={Strings.RallyPoints.Modal.extension}
                id={EXTENSION}
                type="tel"
                maxLength={MAX_LENGTH_3}
                onChange={onTextBoxChange}
                value={formData?.extension || ""}
              />
            </Col>
            <Col lg="6">
              <SLFileUpload
                label={Strings.RallyPoints.Modal.image}
                errorMessage={formError?.imageError}
                onChange={uploadRallyPointImage}
                fileName={fileName}
                accept={ACCEPTED_IMAGE_FORMATS}
                toolTipMessage={Strings.RallyPoints.toolTipMessage.replace(
                  "[RESOLUTION]",
                  `${MIN_WIDHT}X${MIN_HEIGHT}`
                )}
              ></SLFileUpload>
              {rallyId && (
                <div className="rally-img-preview">
                  <img src={formData?.image}></img>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={isDisable}
            onClick={() => {
              !load && handleSave();
            }}
          >
            {load ? (
              <output className="spinner-border sm"></output>
            ) : rallyId ? (
              Strings.RallyPoints.Modal.updateBtn
            ) : (
              Strings.RallyPoints.Modal.addBtn
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteConfModal
        onHide={handleDeletePopUp}
        show={showDeletePopup}
        title={Strings.RallyPoints.RemoveRP.Title}
        desc={Strings.RallyPoints.RemoveRP.Info}
        handleDelete={deleteConfirm}
        isDisabled={isDisable}
      />
    </>
  );
};

export default RallyPoints;
