/**
 * @file   src\containers\ManageUsers.tsx
 * @brief  ManageUsers page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from "../assets/strings/Strings.json";
import {
  Button,
  Col,
  Row,
  Table,
  Form,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import {
  updateUser,
  deleteUser,
  addUser,
  resetPassword,
} from "../services/manageUserService";
import {
  downloadCSV,
  formatPhoneNumber,
  getLocalStorage,
  moveToTop,
  pushAndFormatCSVArray,
} from "../helpers/common";

import {
  HTTP_RESPONSE_STATUS_200,
  MAX_LENGTH_255,
  MAX_LENGTH_45,
  STATUS_0,
  STATUS_1,
  PHONE,
  EDITED,
  ONE,
  PAGE_SIZE_20,
  ZERO,
  csvDownload,
  USER_INFO_KEY,
  tableHeaders,
  MAX_FILE_SIZE_2MB,
  USER_ACTIVE,
  EXTENSION,
  MAX_LENGTH_3,
} from "../constants/common";

import {
  phoneFormat,
  emailValidation,
  nameValidation,
  phoneNumberValidation,
  numberSpecialCharValidation,
  numberValidation,
  checkIsHtml,
  numberOnly,
} from "../helpers/validations";
import TableHeader from "../components/common/TableHeader";
import Paginate from "../components/common/Paginate";
import Search from "../components/common/Search";
import ICAddBulkUsers from "../assets/img/icons/AddBulkUsers";
import SLInput from "../components/SLInput";
import ICCopy from "../assets/img/icons/Copy";
import ICEdit from "../assets/img/icons/Edit";
import ICRemove from "../assets/img/icons/Remove";
import BulkUserUploadModal from "../components/BulkUserUploadModal";
import { toast } from "react-toastify";
import DeleteConfModal from "../components/common/DeleteConfModal";
import {
  ENTER_DEPARTMENT,
  ENTER_EMAIL,
  ENTER_FIRST_NAME,
  ENTER_LAST_NAME,
  ENTER_PHONE,
  ENTER_VALID_PHONE,
  INVALID_CONTENT,
  INVALID_EMAIL,
  INVALID_FILE_SIZE,
  SELECT_BUILDING,
  SELECT_FILE,
} from "../constants/validationMessages";
import {
  AddUserForm,
  AddUserErrorForm,
  Payload,
  GetUserDetails,
  BulkUploadError,
} from "../interfaces/ManageUsers";
import { fetchUserDetails, fetchUserList } from "../Redux/actions/manageUser";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import { resetUserDetails } from "../Redux/Slices/manageUserSlice";
import { bulkUserUpload } from "../services/fileUploadService";
import { BulkUpload } from "../interfaces/FileUpload";
import { fetchBuildingList } from "../Redux/actions/manageBuildings";
import SLMultiSelect from "../components/SLMultiSelect";
import { MultifilterObjects } from "../interfaces/GeneralInterface";
import { getBuildngs } from "../helpers/manageUser";
import { GetList } from "../interfaces/Buildings";
const ManageUsers = () => {
  //Initialise user form data
  const intialAddUser = {
    firstName: "",
    lastName: "",
    phone: "",
    extension: "",
    email: "",
    department: "",
    isActive: true,
    isAdmin: false,
    buildingIds: [] as number[],
  };
  //Initialise user form errors for each text fields
  const initialAddUserError = {
    firstNameError: "",
    lastNameError: "",
    phoneError: "",
    emailError: "",
    buildingError: "",
    departmentError: "",
  };
  const copyLink = "https://spotlightsafetyapp.com/download";
  //Initialise different state variables
  const [userList, setUserList] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);
  const [buildingList, setBuildingList] = useState<any>([]);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [page, setPage] = useState<number>(ONE);
  const [perPage, setPerPage] = useState<number>(PAGE_SIZE_20);
  const [totalRecord, setTotalRecord] = useState<number>(ZERO);
  const [searchText, setSearchText] = useState<string>("");
  const [userId, setUserId] = useState<number>(null);
  const [loggedInUserId, setLoggedInUser] = useState<number>(null);
  const [userDetails, setUserDetails] = useState<AddUserForm>(intialAddUser);
  const [userDetailsError, setUserDetailsError] =
    useState<AddUserErrorForm>(initialAddUserError);
  const [bulkUploadError, setBulkUploadError] = useState<BulkUploadError>({
    fileError: "",
  });
  const [formatedPhone, setFormatedPhone] = useState<string>("");
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [showSafetyConfirm, setShowSafetyConfirm] = useState<boolean>(false);
  const [safetyConfirmType, setSafetyConfirmType] = useState<string>("");
  const [userData, setuserData] = useState<any>([]);
  const [showRemoveUser, setShow] = useState<boolean>(false);
  const [showBulkUser, setBulkUserShow] = useState<boolean>(false);
  const [isDisable, setBtnDisable] = useState<boolean>(false);
  const [bulkUploadDisable, setBulUploadButton] = useState<boolean>(false);
  const [showAddUser, setAddUserShow] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>(null);
  const [filterBuildngs, setFilterBuildngs] = useState<MultifilterObjects[]>(
    []
  );
  // Handle show bulk user modal.
  const handleBulkUserModal = (state: boolean) => {
    setSelectedFile(null);
    setFileName(null);
    setBulkUploadError((error) => ({
      ...error,
      fileError: null,
    }));
    setBulkUserShow(state);
    setBulUploadButton(false);
  };

  const usersLists = useAppSelector((state: any) => state.manageUser?.list); //User list from redux store

  const usersListsloading = useAppSelector(
    (state: any) => state.manageUser?.userListIsLoading
  ); //User list loading flag from redux store
  const dispatch = useAppDispatch();

  const editedUserDetails = useAppSelector(
    (state: any) => state.manageUser?.userDetails
  ); //User details from redux store while click edit

  //Building list  from redux store
  const list = useAppSelector((state: any) => state?.building?.list);

  //Update building list into local state
  useEffect(() => {
    setBuildingList(list);
  }, [list]);

  // Handle to setting the editing user details to state.
  useEffect(() => {
    setUserDetails((userDetails) => ({
      ...userDetails,
      firstName: editedUserDetails?.firstName,
      lastName: editedUserDetails?.lastName,
      email: editedUserDetails?.email,
      phone: editedUserDetails?.phone,
      buildingIds: editedUserDetails?.buildings || [],
      department: editedUserDetails?.department,
      isActive: editedUserDetails?.isActive === STATUS_0 ? false : true,
      isAdmin: editedUserDetails?.isAdmin
        ? editedUserDetails?.isAdmin === STATUS_0
          ? false
          : true
        : false,
      userId: editedUserDetails?.userId,
      extension: editedUserDetails?.extension,
    }));
  }, [editedUserDetails]);

  // Handle close the remove user modal.
  const closeRemoveUser = () => {
    setUserId(null);
    setShow(false);
    setSafetyConfirmType("");
  };

  // Handle show the remove user modal.
  const handleRemove = (data: {
    soId: number;
    userId: number;
    isActive: number;
    isAdmin: number;
  }) => {
    setBtnDisable(false);
    if (
      data?.soId !== null &&
      data?.isActive === STATUS_1 &&
      data?.isAdmin === STATUS_1
    ) {
      setSafetyConfirmType("delete");
    }
    setUserId(data?.userId);
    setShow(true);
  };

  // Handle show safety confirmation modal.
  const closeSafetyConfirm = () => {
    setShowSafetyConfirm(false);
    setuserData("");
    setSafetyConfirmType("");
  };

  // Handle close Add user modal.
  const closeAddUser = () => {
    setUserDetailsError(initialAddUserError);
    setAddUserShow(false);
    setIsEditable(false);
    setUserDetails(intialAddUser);
    setUserId(null);
  };

  // Handle show Add user modal.
  const handleOpenUserModal = () => {
    setAddUserShow(true);
    setBtnDisable(false);
    setUserDetailsError(initialAddUserError);
  };

  // Handle show edit user modal.
  const handleEdit = (data: GetUserDetails) => {
    dispatch(fetchUserDetails({ id: data?.userId }));
    handleOpenUserModal();
    setIsEditable(true);
    setUserId(data?.userId);
  };

  const columns = [
    { title: tableHeaders.common.edit, isSort: false, thClass: "text-center" },
    { title: tableHeaders.users.firstName, isSort: false },
    { title: tableHeaders.users.lastName, isSort: false },
    { title: tableHeaders.users.email, isSort: false },
    { title: tableHeaders.users.phone, isSort: false },
    { title: tableHeaders.users.building, isSort: false },
    { title: tableHeaders.users.department, isSort: false },
    {
      title: tableHeaders.users.activeUser,
      isSort: false,
      thClass: "text-center",
    },
    { title: tableHeaders.users.admin, isSort: false, thClass: "text-center" },

    {
      title: tableHeaders.common.remove,
      isSort: false,
      thClass: "text-center",
    },
    {
      title: tableHeaders.users.action,
      thClass: "text-center",
    },
  ];

  // Handle to calling the User list API.
  useEffect(() => {
    dispatch(
      fetchUserList({
        pageNo: page,
        pageSize: perPage,
        searchText: searchText,
      })
    );
  }, [page, perPage]);

  // Clear user details from store in the initial render.
  //Set logged in userId in initial render to state variable for disable remove button for logged in User
  useEffect(() => {
    dispatch(resetUserDetails());
    const userDetails = getLocalStorage(USER_INFO_KEY);
    const userId = userDetails?.userId;
    setLoggedInUser(userId);
    const buildngPayload: GetList = { orderBy: "B_Name", sortOrder: "asc" };
    dispatch(fetchBuildingList(buildngPayload));
  }, []);

  // Handle to format the phone number.
  useEffect(() => {
    const phone = phoneFormat(userDetails.phone);
    setFormatedPhone(phone);
  }, [userDetails.phone]);

  //Create building ids string for filter
  const getFilterBuildngIds = () => {
    let buildingIds = null;
    for (let i = 0; i < filterBuildngs?.length; i++) {
      if (i > 0) {
        buildingIds = buildingIds + "," + filterBuildngs[i].id?.toString();
      } else {
        buildingIds = filterBuildngs[i].id?.toString();
      }
    }
    return buildingIds;
  };

  // Handle Search click
  const handleSearch = () => {
    const buildingIds = getFilterBuildngIds();
    dispatch(
      fetchUserList({
        pageNo: ONE,
        pageSize: perPage,
        searchText: searchText,
        buildingIds: buildingIds,
      })
    );
  };

  // Handle response from the User list api call.
  useEffect(() => {
    if (!usersListsloading) {
      loadUserList();
    }
  }, [usersLists]);

  // Handle setting the response from the User list API call into state.
  const loadUserList = async () => {
    const list = usersLists?.list;
    if (list) {
      const csvArry: [] = pushAndFormatCSVArray(list, csvDownload.users);
      setTotalRecord(usersLists?.totalRecords);
      setCsvData(csvArry);
      setUserList(list);
    } else setTotalRecord(ZERO);
  };

  // Handle updating the state and calling the update User API.
  const updateUsersList = async (data: any, type: string) => {
    const payload = data;
    setBtnDisable(true);
    setIsLoad(true);
    const response: ResponseObjects = await updateUser(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      if (type === EDITED) {
        const buildingIds = getFilterBuildngIds();
        dispatch(
          fetchUserList({
            pageNo: page,
            pageSize: perPage,
            searchText: searchText,
            buildingIds: buildingIds,
          })
        );
        closeAddUser();
        toast.success(response?.message);
      } else {
        const copyList = [...userList];
        const newState = copyList.map((obj) => {
          if (obj.userId === payload?.userId) {
            return {
              ...obj,
              isActive: payload?.isActive === false ? STATUS_0 : STATUS_1,
              isAdmin: payload?.isAdmin === false ? STATUS_0 : STATUS_1,
            };
          }
          return obj;
        });
        const csvArry: [] = pushAndFormatCSVArray(newState, csvDownload.users);
        setUserList(newState);
        setCsvData(csvArry);
        closeSafetyConfirm();
      }
    } else {
      setBtnDisable(false);
      toast.error(response?.message);
    }
    setIsLoad(false);
    return response?.status;
  };

  // Handle updating the state and calling the delete User API.
  const deleteUsers = async (payload: { userId: number }) => {
    setBtnDisable(true);
    const response: ResponseObjects = await deleteUser(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      toast.success(response?.message);
      const copyList: any = [...userList];
      const updatedList = copyList?.filter(function (ls: { userId: number }) {
        return ls.userId !== payload?.userId;
      });
      setUserList(updatedList);
      closeRemoveUser();
    } else {
      setBtnDisable(false);
      toast.error(response?.message);
    }
  };

  // Handle updating the state and calling the add User API.
  const createUser = async (payload: Payload) => {
    payload.buildingIds = buildngPayload(payload.buildingIds);
    setBtnDisable(true);
    setIsLoad(true);
    const response: ResponseObjects = await addUser(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      toast.success(response?.message);
      closeAddUser();
      if (page !== ONE) {
        setPage(ONE);
      } else {
        dispatch(
          fetchUserList({
            pageNo: page,
            pageSize: perPage,
            searchText: searchText,
          })
        );
      }
    } else {
      setBtnDisable(false);
      toast.error(response?.message);
    }
    setIsLoad(false);
  };

  // Handle updating the Active switch state.
  const handleActiveSwitch = (data: Payload) => {
    if (
      data?.soId !== null &&
      data?.isActive === STATUS_1 &&
      data?.isAdmin === STATUS_1
    ) {
      setShowSafetyConfirm(true);
      setuserData(data);
      setSafetyConfirmType("active");
    } else {
      handleActiveUpdate(data);
    }
  };

  // Handle to call the update User API.
  const handleActiveUpdate = (data: Payload) => {
    const payload = {
      userId: data?.userId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone: data?.phone,
      extension: data?.extension,
      buildingIds:
        data?.buildingCount > ZERO ? buildngPayload(data?.buildings) : [],
      department: data?.department,
      isActive: data?.isActive === STATUS_0 ? true : false,
      isAdmin: data?.isAdmin === STATUS_0 ? false : true,
      isSuperAdmin: false,
    };
    updateUsersList(payload, "");
  };

  // Handle updating the Admin switch state.
  const handleAdminSwitch = (data: Payload) => {
    if (data?.soId !== null && data?.isAdmin === STATUS_1) {
      setShowSafetyConfirm(true);
      setuserData(data);
    } else {
      handleAdminUpdate(data);
    }
  };

  const buildngPayload = (buildngData: any) => {
    const buildngIds = buildngData?.map((item: { id: number; bId: number }) => {
      if (item?.id || item?.bId) {
        return item?.id || item?.bId;
      }
      return item;
    });

    // This snippet fore remove 'select all' option from the array
    const index = buildngIds?.indexOf(ZERO);
    if (index > -1) {
      // only splice array when item is found
      buildngIds?.splice(index, ONE); // 2nd parameter means remove one item only
    }
    return buildngIds;
  };

  // Handle to call the update User API.
  const handleAdminUpdate = (data: Payload) => {
    const payload = {
      userId: data?.userId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone: data?.phone,
      extension: data?.extension,
      buildingIds:
        data?.buildingCount > ZERO ? buildngPayload(data?.buildings) : [],
      department: data?.department,
      isActive: data?.isActive === STATUS_0 ? false : true,
      isAdmin: data?.isAdmin === STATUS_0 ? true : false,
      isSuperAdmin: false,
    };

    updateUsersList(payload, "");
  };

  // Handle to call the delete User API.
  const handleDeleteConfirm = () => {
    deleteUsers({
      userId: userId,
    });
  };

  // page change and state change
  const pageChange = (page: number) => {
    if (page) {
      setPage(page);
      moveToTop();
    }
  };

  // page size change and state change
  const perPageChange = (page: number) => {
    if (page) {
      setPage(ONE);
      setPerPage(page);
      moveToTop();
    }
  };

  // Handle onChange Text change to state
  const onTextUserChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    if (currentId === PHONE) {
      if (numberSpecialCharValidation.test(value)) {
        const numbers = value.match(/\d+/g);
        const fullNumber = numbers.join("");
        setUserDetails((userDetails) => ({
          ...userDetails,
          [currentId]: fullNumber,
        }));
      } else if (!numberValidation.test(value)) {
        setUserDetails((userDetails) => ({
          ...userDetails,
          [currentId]: value,
        }));
      }
    } else if (currentId === EXTENSION) {
      if (value) {
        if (numberOnly.test(value)) {
          setUserDetails((userDetails) => ({
            ...userDetails,
            [currentId]: value,
          }));
        }
      } else {
        setUserDetails((userDetails) => ({
          ...userDetails,
          [currentId]: "",
        }));
      }
    } else {
      setUserDetails((userDetails) => ({
        ...userDetails,
        [currentId]: value,
      }));
    }
  };

  // Handle active switch change.
  const onSwitchUserChange = () => {
    setUserDetails((userDetails) => ({
      ...userDetails,
      isActive: userDetails.isActive === false,
      isAdmin: userDetails.isAdmin === false ? false : true,
    }));
  };

  // Handle admin switch change.
  const onSwitchUserAdminChange = () => {
    setUserDetails((userDetails) => ({
      ...userDetails,
      isAdmin: userDetails.isAdmin === false,
    }));
  };

  // Handle building filter selection.
  const handleBuildingFilterSelection = (
    selectedItems: MultifilterObjects[]
  ) => {
    setFilterBuildngs(selectedItems);
  };

  // Handle error validation in form.
  const validateForm = () => {
    setUserDetailsError(initialAddUserError);
    let isValid = true;
    const firstName = userDetails?.firstName?.trim();
    const lastName = userDetails?.lastName?.trim();
    const phone = userDetails?.phone?.trim();
    const email = userDetails?.email?.trim();
    //const building = userDetails?.building?.trim();
    const buildingIds = userDetails?.buildingIds;

    const department = userDetails?.department?.trim();

    if (email) {
      if (!emailValidation(email)) {
        setUserDetailsError((userDetailsError) => ({
          ...userDetailsError,
          emailError: INVALID_EMAIL,
        }));
        isValid = false;
      }
    }
    if (firstName) {
      const nameValid = nameValidation(firstName);
      if (nameValid !== "") {
        setUserDetailsError((userDetailsError) => ({
          ...userDetailsError,
          firstNameError: nameValid,
        }));
        isValid = false;
      }
    }
    if (lastName) {
      const nameValid = nameValidation(lastName);
      if (nameValid !== "") {
        setUserDetailsError((userDetailsError) => ({
          ...userDetailsError,
          lastNameError: nameValid,
        }));
        isValid = false;
      }
    }

    if (buildingIds?.length === ZERO) {
      setUserDetailsError((userDetailsError) => ({
        ...userDetailsError,
        buildingError: SELECT_BUILDING,
      }));
      isValid = false;
    }
    if (department) {
      const isHtml = checkIsHtml(department);
      if (!isHtml) {
        setUserDetailsError((userDetailsError) => ({
          ...userDetailsError,
          departmentError: INVALID_CONTENT,
        }));
        isValid = false;
      }
    }
    if (phone && formatedPhone) {
      const phoneValidation = phoneNumberValidation(formatedPhone);
      if (!phoneValidation) {
        setUserDetailsError((userDetailsError) => ({
          ...userDetailsError,
          phoneError: ENTER_VALID_PHONE,
        }));
        isValid = false;
      }
    }

    if (
      !firstName &&
      !lastName &&
      !phone &&
      !email &&
      buildingIds?.length === ZERO &&
      !department
    ) {
      setUserDetailsError((userDetailsError) => ({
        ...userDetailsError,
        firstNameError: ENTER_FIRST_NAME,
        lastNameError: ENTER_LAST_NAME,
        phoneError: ENTER_PHONE,
        emailError: ENTER_EMAIL,
        buildingError: SELECT_BUILDING,
        departmentError: ENTER_DEPARTMENT,
      }));
      isValid = false;
    } else if (!firstName) {
      setUserDetailsError((userDetailsError) => ({
        ...userDetailsError,
        firstNameError: ENTER_FIRST_NAME,
      }));
      isValid = false;
    }
    if (!lastName) {
      setUserDetailsError((userDetailsError) => ({
        ...userDetailsError,
        lastNameError: ENTER_LAST_NAME,
      }));
      isValid = false;
    }
    if (!phone) {
      setUserDetailsError((userDetailsError) => ({
        ...userDetailsError,
        phoneError: ENTER_PHONE,
      }));
      isValid = false;
    }
    if (!email) {
      setUserDetailsError((userDetailsError) => ({
        ...userDetailsError,
        emailError: ENTER_EMAIL,
      }));
      isValid = false;
    }
    if (buildingIds?.length === ZERO) {
      setUserDetailsError((userDetailsError) => ({
        ...userDetailsError,
        buildingError: SELECT_BUILDING,
      }));
      isValid = false;
    }
    if (!department) {
      setUserDetailsError((userDetailsError) => ({
        ...userDetailsError,
        departmentError: ENTER_DEPARTMENT,
      }));
      isValid = false;
    }
    return isValid;
  };

  //Handle add/Edit user API call.
  const handleSave = () => {
    const isValid = validateForm();
    if (isValid) {
      if (isEditable) {
        const payload: Payload = {
          userId: userId,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          phone: userDetails.phone,
          extension: userDetails.extension,
          buildingIds: buildngPayload(userDetails.buildingIds),
          department: userDetails.department,
          isActive: userDetails.isActive,
          isAdmin: userDetails.isAdmin,
          isSuperAdmin: false,
        };
        updateUsersList(payload, EDITED);
      } else createUser(userDetails);
    }
  };

  //Bulk user upload API call.
  const handleBulkUserUpload = async () => {
    setBulkUploadError((error) => ({
      ...error,
      fileError: null,
    }));
    if (selectedFile) {
      if (selectedFile?.size > MAX_FILE_SIZE_2MB) {
        setBulkUploadError((error) => ({
          ...error,
          fileError: INVALID_FILE_SIZE,
        }));
      } else {
        setBulUploadButton(true);
        const reqPayload: BulkUpload = { userData: selectedFile };

        const response: ResponseObjects = await bulkUserUpload(reqPayload);

        if (response?.status === HTTP_RESPONSE_STATUS_200) {
          toast.success(response?.message);
          handleBulkUserModal(false);
          setSelectedFile(null);
        } else {
          setBulUploadButton(false);
          toast.error(response?.message);
        }
      }
    } else {
      setBulkUploadError((error) => ({
        ...error,
        fileError: SELECT_FILE,
      }));
    }
  };

  //Upload bulk user csv functionality
  const uploadFile = async (event: React.ChangeEvent<HTMLFormElement>) => {
    try {
      const file = event.target?.files[ZERO];
      setFileName(file?.name);
      setSelectedFile(file);
    } catch (error) {
      console.log(error);
    }
  };

  // handle to copy the Link to clipboard.
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(copyLink);
      toast.success("Invitation Link copied to clipboard");
    } catch (err) {
      console.error("Failed to copy Invitation Link to clipboard", err);
    }
  };

  // handle reset Password click.
  const handleResetPassword = async (userId: number, isActive: boolean) => {
    try {
      if (isActive) {
        const response: ResponseObjects = await resetPassword({
          userId: userId,
        });
        if (response?.status === HTTP_RESPONSE_STATUS_200) {
          toast.success(response?.message);
        } else {
          toast.error(response?.message);
        }
      } else {
        alert("User was deactivated.");
      }
    } catch (err) {
      console.error("error", err);
      alert("Something went wrong");
    }
  };

  // Handle building options selection from add/edit modal.
  const handleBuildingSelection = (selectedItems: any) => {
    setUserDetails((userDetails) => ({
      ...userDetails,
      buildingIds: selectedItems?.map((item: { id: number }) => {
        return item?.id;
      }),
    }));
  };

  // Bind user list in the UI
  const bindUsersList = () => {
    if (usersListsloading) {
      return (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      );
    } else {
      if (userList?.length > ZERO) {
        return (
          <Table responsive>
            <TableHeader columns={columns} />
            <tbody>
              {userList &&
                userList.length > ZERO &&
                userList.map((items: any) => (
                  <tr key={items?.userId}>
                    <td
                      className="text-center ic-blue"
                      onClick={() => {
                        handleEdit(items);
                      }}
                    >
                      <ICEdit />
                    </td>
                    <td>
                      <strong>{items?.firstName}</strong>
                    </td>
                    <td>
                      <strong>{items?.lastName}</strong>
                    </td>
                    <td width={100}>{items?.email}</td>
                    <td className="text-nowrap">
                      {formatPhoneNumber(items?.phone)}{" "}
                      {items?.extension && (
                        <span>
                          {" "}
                          <b>&nbsp;Ext:</b> {items?.extension}
                        </span>
                      )}
                    </td>
                    <td>
                      {items?.buildingCount > ZERO &&
                        getBuildngs(items?.buildings)}
                    </td>
                    <td>{items?.department}</td>
                    <td className="tb-switch-center">
                      <Form.Check
                        disabled={loggedInUserId === items?.userId}
                        type="switch"
                        value={items?.isActive}
                        checked={items?.isActive}
                        onChange={() => handleActiveSwitch(items)}
                        id="custom-switch"
                      />
                    </td>
                    <td className="tb-switch-center">
                      <Form.Check
                        type="switch"
                        value={items?.isAdmin}
                        checked={items?.isAdmin}
                        onChange={() => handleAdminSwitch(items)}
                        id="custom-switch"
                        disabled={
                          items?.isActive === STATUS_0 ||
                          loggedInUserId === items?.userId
                        }
                      />
                    </td>

                    <td
                      className={
                        loggedInUserId === items?.userId
                          ? "text-center ic-yellow disabled-ic"
                          : "text-center ic-yellow"
                      }
                      onClick={() => {
                        handleRemove(items);
                      }}
                    >
                      <ICRemove />
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() =>
                          handleResetPassword(
                            items?.userId,
                            items?.isActive === USER_ACTIVE
                          )
                        }
                      >
                        Reset Password
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        );
      } else {
        return (
          <Col className="text-center mt-3 mb-3">
            {Strings.ManageUser.NoDataMessage}
          </Col>
        );
      }
    }
  };

  return (
    <>
      <Row className="tilte-wrapper">
        <Col md="8">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.ManageUser.Title}
          </h1>
        </Col>
        <Col md="4" className="d-flex justify-content-end">
          <span
            className="add-users d-flex align-items-center justify-content-center"
            onClick={() => handleBulkUserModal(true)}
          >
            {Strings.ManageUser.UploadLink}
            <ICAddBulkUsers />
          </span>
        </Col>
      </Row>
      <div className="content-wrapper dashboard welcome">
        <Row>
          <Col xl="4" lg="4" md="12">
            <h3>
              {Strings.ManageUser.InviteTitle}
              <span className="d-block">{Strings.ManageUser.InviteTxt}</span>
            </h3>
          </Col>
          <Col xl="3" lg="4" md="7" className="d-flex copy-link">
            <SLInput name="link" id="link" value={copyLink} readOnly />
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-disabled">
                  {Strings.ManageUser.Copy}
                </Tooltip>
              }
            >
              <span
                onClick={handleCopyLink}
                className="copy-btn d-flex align-items-center justify-content-center"
              >
                <ICCopy />
              </span>
            </OverlayTrigger>
          </Col>
          <Col
            xl="5"
            lg="4"
            md="5"
            className="users-info-txt d-flex align-items-center"
          >
            <p>{Strings.ManageUser.InviteNote}</p>
          </Col>
        </Row>
      </div>
      <Row className="action-wrapper user-search">
        <Col md="5" xl="9">
          <Row>
            <Col xl="4">
              <Search
                searchText={searchText}
                setSearchText={setSearchText}
                notAllowEnterKeyPressSearch={true}
              />
            </Col>
            <Col xl="4">
              <SLMultiSelect
                options={buildingList}
                handleSelection={handleBuildingFilterSelection}
              />
            </Col>
            <Col xl="3">
              <Button variant="primary" onClick={() => handleSearch()}>
                Search
              </Button>
            </Col>
          </Row>
        </Col>

        <Col md="7" xl="3" className="text-end">
          <Button
            variant="outline-primary"
            onClick={() => handleOpenUserModal()}
          >
            {Strings.ManageUser.AddUserBtn}
          </Button>
          <Button
            disabled={userList.length === ZERO}
            onClick={() => downloadCSV(csvData, csvDownload.users)}
          >
            {Strings.common.dowloadCSV}
          </Button>
        </Col>
      </Row>

      {bindUsersList()}
      {totalRecord !== ZERO && (
        <Paginate
          totalRecords={totalRecord}
          currentPage={page}
          perPage={perPage}
          onClick={pageChange}
          onShowClick={perPageChange}
        />
      )}

      {/* Remove User Modal */}
      <DeleteConfModal
        onHide={closeRemoveUser}
        show={showRemoveUser}
        title={Strings.ManageUser.RemoveUsers.Title}
        desc={
          safetyConfirmType === "delete"
            ? Strings.ManageUser.RemoveUsers.deleteInfoSafetyOfficer
            : Strings.ManageUser.RemoveUsers.Info
        }
        handleDelete={handleDeleteConfirm}
        isDisabled={isDisable}
      />
      <DeleteConfModal
        onHide={closeSafetyConfirm}
        show={showSafetyConfirm}
        title=""
        desc={Strings.ManageUser.RemoveUsers.changeStatusInfoSafetyOfficer}
        handleDelete={() => {
          safetyConfirmType === "active"
            ? handleActiveUpdate(userData)
            : handleAdminUpdate(userData);
        }}
        isDisabled={isDisable}
      />

      {/* Add User Modal */}
      <Modal
        show={showAddUser}
        onHide={closeAddUser}
        backdrop="static"
        centered
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {userId
              ? Strings.ManageUser.AddUser.EditUser
              : Strings.ManageUser.AddUser.AddUser}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-user">
          <Row>
            <Col lg="6">
              <SLInput
                label={Strings.ManageUser.AddUser.FirstName}
                id="firstName"
                name="firstName"
                value={userDetails.firstName}
                onChange={onTextUserChange}
                errorMessage={userDetailsError.firstNameError}
                maxLength={MAX_LENGTH_45}
              />
            </Col>
            <Col lg="6">
              <SLInput
                label={Strings.ManageUser.AddUser.LastName}
                id="lastName"
                name="lastName"
                value={userDetails.lastName}
                onChange={onTextUserChange}
                errorMessage={userDetailsError.lastNameError}
                maxLength={MAX_LENGTH_45}
              />
            </Col>
            <Col lg="6">
              <SLInput
                label={Strings.ManageUser.AddUser.Email}
                id="email"
                name="email"
                type="email"
                value={userDetails.email}
                onChange={onTextUserChange}
                errorMessage={userDetailsError.emailError}
                disabled={isEditable}
                maxLength={MAX_LENGTH_45}
              />
            </Col>
            <Col lg="4">
              <SLInput
                name="phone"
                id={PHONE}
                type="tel"
                maxLength={MAX_LENGTH_45}
                label={Strings.ManageUser.AddUser.Phone}
                value={formatedPhone || ""}
                onChange={onTextUserChange}
                errorMessage={userDetailsError.phoneError}
                toolTipMessage={
                  isEditable && userDetails?.isAdmin ? Strings.phoneInfo : ""
                }
              />
            </Col>
            <Col md="2">
              <SLInput
                label={Strings.ManageUser.AddUser.Extension}
                id={EXTENSION}
                type="tel"
                maxLength={MAX_LENGTH_3}
                onChange={onTextUserChange}
                value={userDetails?.extension || ""}
              />
            </Col>
            <Col lg="6">
              <SLMultiSelect
                options={buildingList}
                id="buildingIds"
                handleSelection={handleBuildingSelection}
                errorMessage={userDetailsError.buildingError}
                label={Strings.ManageUser.AddUser.Building}
                value={userDetails?.buildingIds}
              />
            </Col>
            <Col lg="6">
              <SLInput
                label={Strings.ManageUser.AddUser.Department}
                id="department"
                name="department"
                value={userDetails.department}
                onChange={onTextUserChange}
                errorMessage={userDetailsError.departmentError}
                maxLength={MAX_LENGTH_255}
              />
            </Col>
            <Col lg="2" md="3">
              <label className="label-common">
                {Strings.ManageUser.AddUser.ActiveUser}
              </label>
              <Form.Check
                type="switch"
                id="custom-switch"
                className="mt-3"
                value={userDetails.isActive}
                checked={userDetails.isActive}
                onChange={onSwitchUserChange}
                disabled={loggedInUserId === userDetails?.userId}
              />
            </Col>
            <Col lg="2" md="3" className="admin">
              <label className="label-common">
                {Strings.ManageUser.AddUser.Admin}
              </label>
              <Form.Check
                type="switch"
                id="custom-switch"
                className="mt-3"
                value={userDetails.isAdmin}
                checked={userDetails.isAdmin}
                onChange={onSwitchUserAdminChange}
                disabled={
                  userDetails.isActive === false ||
                  loggedInUserId === userDetails?.userId
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="pt-3">
          <Button variant="primary" onClick={() => handleSave()}>
            {isLoad ? (
              <output className="spinner-border sm"></output>
            ) : userId ? (
              Strings.ManageUser.AddUser.updateBtn
            ) : (
              Strings.ManageUser.AddUser.saveBtn
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/*Bulk user upload modal */}
      <BulkUserUploadModal
        handleBulkUserModal={handleBulkUserModal}
        show={showBulkUser}
        isDisable={bulkUploadDisable}
        uploadFile={uploadFile}
        handleConfirmUpload={handleBulkUserUpload}
        errorMessage={bulkUploadError?.fileError}
        fileName={fileName}
      />
    </>
  );
};

export default ManageUsers;
