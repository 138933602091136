import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import {
  AddUpdteBuilding,
  DeleteBuilding,
  GetList,
} from "../interfaces/Buildings";

//Api call for fetch building lists.
export async function getBuildingList(payload?: GetList) {
  console.log(payload);
  return await apiService.apiCall(
    endPoints.GET_BUILDINGS_API,
    HTTP_GET,
    payload
  );
}
//Api call for update building details.
export async function updateBuilding(payload?: AddUpdteBuilding) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_BUILDING_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
//Api call for delete a building.
export async function deleteBuilding(payload?: DeleteBuilding) {
  const res = await apiService.apiCall(
    endPoints.DELETE_BUILDING_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for add a building.
export async function addBuilding(payload?: AddUpdteBuilding) {
  const res = await apiService.apiCall(
    endPoints.ADD_BUILDING_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
