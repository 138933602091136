import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBuildingList } from "../../services/manageBuildingService";
import { GetList } from "../../interfaces/Buildings";

// Action creator to call the get rally point function with a payload to invoke the API.
export const fetchBuildingList = createAsyncThunk(
  "buildings/fetchBuildingList",
  (payload: GetList) => {
    return getBuildingList(payload);
  }
);
