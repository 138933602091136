/**
 * @file   src\components\AddUpateBuildingModal.tsx
 * @brief  Add/Edit building form.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import SLInput from "../components/SLInput";
import Strings from "../assets/strings/Strings.json";
import { Button, Col, Row, Modal } from "react-bootstrap";
import SLSelect from "../components/SLSelect";
import { MAX_LENGTH_12, MAX_LENGTH_255 } from "../constants/common";
const AddUpateBuildingModal = (props: any) => {
  const {
    formData,
    onTextBoxChange,
    formError,
    states,
    handleStateSelection,
    handleSave,
    isLoad,
    show,
    closeModal,
  } = props;

  return (
    <Modal
      show={show}
      onHide={closeModal}
      backdrop="static"
      centered
      keyboard={false}
      size="lg"
    >
      {" "}
      <Modal.Header closeButton>
        <Modal.Title>
          {formData?.buildngId
            ? Strings.buildings.editBuildngTitle
            : Strings.buildings.addBuildngTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-user">
        <Row>
          <Col xl="6" md="12">
            <SLInput
              id="name"
              label={Strings.buildings.addBuildng}
              type="text"
              value={formData?.name}
              onChange={onTextBoxChange}
              errorMessage={formError?.nameError}
              maxLength={MAX_LENGTH_255}
            />
          </Col>
          <Col xl="6" md="12">
            <SLInput
              id="city"
              label={Strings.buildings.city}
              type="text"
              value={formData?.city}
              onChange={onTextBoxChange}
              errorMessage={formError?.cityError}
              maxLength={MAX_LENGTH_255}
            />
          </Col>
        </Row>
        <Row>
          <Col xl="6" md="12">
            <SLInput
              id="address1"
              label={Strings.buildings.buildngAddress}
              type="text"
              value={formData?.address1}
              onChange={onTextBoxChange}
              errorMessage={formError?.mainAddressError}
              maxLength={MAX_LENGTH_255}
            />
          </Col>
          <Col xl="6" md="12">
            <SLSelect
              label={Strings.buildings.state}
              list={states}
              handleSelection={handleStateSelection}
              selectedValue={formData?.state}
              errorMessage={formError?.stateError}
              disabled={false}
              DefaultItemText="Select State"
            />
          </Col>
        </Row>

        <Row>
          <Col xl="6" md="12">
            <SLInput
              id="address2"
              label={Strings.buildings.buildngAddress2}
              type="text"
              value={formData?.address2}
              onChange={onTextBoxChange}
              maxLength={MAX_LENGTH_255}
            />
          </Col>
          <Col xl="6" md="12">
            <SLInput
              id="zip"
              label={Strings.buildings.zip}
              type="text"
              value={formData?.zip || ""}
              onChange={onTextBoxChange}
              errorMessage={formError?.zipError}
              maxLength={MAX_LENGTH_12}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="pt-3">
        <Button variant="secondary" onClick={() => closeModal()}>
          {Strings.common.cancelBtn}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            !isLoad && handleSave();
          }}
        >
          {isLoad ? (
            <output className="spinner-border sm"></output>
          ) : formData?.buildngId ? (
            Strings.common.updateBtn
          ) : (
            Strings.common.addBtn
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddUpateBuildingModal;
