/**
 * @file   src\components\common\Search.tsx
 * @brief  Searching in listing pages.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import SLInputIcon from "../SLInputIcon";
import ICSearch from "../../assets/img/icons/Search";
import Strings from "../../assets/strings/Strings.json";
import { SearchInterface } from "../../interfaces/Search";
import { ENTER_KEY_CODE, ONE } from "../../constants/common";

const Search = (props: SearchInterface) => {
  const {
    onClick,
    pageSize,
    setPage,
    page,
    searchText,
    setSearchText,
    notAllowEnterKeyPressSearch,
  } = props;

  const onSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    if (page && page !== ONE) {
      setPage(ONE);
    } else {
      // setPage(1);
      onClick({
        searchText: searchText,
        pageNo: ONE,
        pageSize: pageSize,
      });
    }
  };

  const submitFormOnKeyDown = (event: any) => {
    if (!notAllowEnterKeyPressSearch && event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      handleSearch();
    }
  };
  return (
    <SLInputIcon
      name="search-txt"
      placeholder={Strings.common.searchPlaceholder}
      id="search"
      value={searchText}
      onChange={onSearchTextChange}
      onClick={handleSearch}
      onKeyDown={submitFormOnKeyDown}
      type="text"
      icon={<ICSearch />}
    />
  );
};

export default Search;
