/**
 * @file   src\components\SLMultiSelect.tsx
 * @brief  Reusable Multi Select component.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import "./SLMultiSelect.scss";
import Multiselect from "multiselect-react-dropdown";
import {
  MultifilterObjects,
  MultiSelect,
} from "../interfaces/GeneralInterface";
import { useEffect, useState } from "react";
import { ONE, ZERO } from "../constants/common";

const SLMultiSelect = (props: MultiSelect) => {
  const [selectedOptions, setSelectedOptions] = useState<MultifilterObjects[]>(
    []
  );
  const selectAllOption = { name: "Select All", id: ZERO };
  let updatedOptions = props?.options;
  //Adding 'select all' option in building dropdown while add/edit user
  if (props?.id) {
    updatedOptions = [selectAllOption, ...updatedOptions];
  }

  //Updating the  state by adding the selected option
  const onSelect = (
    selectedList: MultifilterObjects[],
    selectedItem: MultifilterObjects
  ) => {
    //Selecting the option 'select all'
    if (selectedItem?.id === ZERO) {
      setSelectedOptions(
        updatedOptions?.map((item: { id: number; name: string }) => {
          return { id: item?.id, name: item?.name };
        })
      );
    } else {
      let selectedItems = selectedList;
      if (props?.options?.length === selectedList?.length) {
        selectedItems = [selectAllOption, ...selectedItems];
      }
      setSelectedOptions(
        selectedItems?.map((item: { id: number; name: string }) => {
          return { id: item?.id, name: item?.name };
        })
      );
    }
  };

  //Updating the  state by removing the  option
  const onRemove = (
    selectedList: MultifilterObjects[],
    removedOption: MultifilterObjects
  ) => {
    //DeSelecting the option 'select all'
    if (removedOption?.id === ZERO) {
      setSelectedOptions([]);
    } else {
      const updatedOptions = selectedList?.filter(function (ls: {
        id: number;
      }) {
        return ls.id !== ZERO;
      });
      setSelectedOptions(
        updatedOptions?.map((item: { id: number; name: string }) => {
          return { id: item?.id, name: item?.name };
        })
      );
    }
  };

  //This useeffect for Showing the previously selected items while editing user
  useEffect(() => {
    if (selectedOptions?.length === ZERO && props?.value?.length > ZERO) {
      let selectedItems = props.value;
      if (props?.options?.length === props?.value?.length) {
        selectedItems = [selectAllOption, ...selectedItems];
      }
      setSelectedOptions(selectedItems);
    }
  }, [props.value]);

  //Updating the user state while selecting the options
  useEffect(() => {
    props?.handleSelection(selectedOptions);
  }, [selectedOptions]);

  const optionsCount =
    selectedOptions?.length > props?.options?.length
      ? selectedOptions?.length - ONE
      : selectedOptions?.length;
  const isOptionsSelected = optionsCount > ZERO;
  let formClass = "";

  if (isOptionsSelected) {
    if (props?.errorMessage) {
      formClass = "selected-buildings form-error";
    } else {
      formClass = "selected-buildings";
    }
  } else {
    if (props?.errorMessage) {
      formClass = "form-error";
    } else {
      formClass = "";
    }
  }

  return (
    <div className="sl-multiselect">
      {props.label && <label>{props.label}</label>}
      <Multiselect
        displayValue="name"
        selectedValues={selectedOptions}
        onRemove={onRemove}
        onSelect={onSelect}
        placeholder={`${!isOptionsSelected ? "Select Building / Location" : optionsCount + " Building / Location Selected"}`}
        showArrow={true}
        hideSelectedList={true}
        avoidHighlightFirstOption={true}
        showCheckbox
        className={formClass}
        options={updatedOptions}
      />
      {props.errorMessage && (
        <div className="error-msg">{props.errorMessage}</div>
      )}
    </div>
  );
};

export default SLMultiSelect;
